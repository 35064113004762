// ** Initial State
const initialState = {
  allData: [],
  allroles: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedUser: null,
  selectedRole: null,
  bookEdit: false,
  token: JSON.parse(localStorage.getItem("accessToken")),
  customPagination: null,
  found_results: '',
  entries_per_page: '',
  total_results: '',
}

// console.log('sssssssssssss', selectedUser)

const roles = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ROLE':
      // return { ...state, allroles: [...action.data] }
      return { ...state, allroles: [...action.data], customPagination: action.customTotal, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results }

    case 'GET_ROLE_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER':
      // console.log(action)
      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_USER':
      return { ...state, allData: action.data }
    case 'DELETE_ROLE':
      // console.log(action.data)
      return { ...state, allroles: action.data }
    case 'EDITROLE':
      return { ...state, selectedRole: action.data }
    default:
      return { ...state }
  }
}
export default roles
