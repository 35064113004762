// ** Initial State
const initialState = {
  data: [],
  selectedOperator: null,
  loading: false,
  token: JSON.parse(localStorage.getItem("accessToken")),
};

const operatorCredits = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_DATA":
      return {
        ...state,
        data: action.data,
      };
    case "GET_DETAIL":
      return {
        ...state,
        selectedOperator: action.data,
      };
    case "UPDATE_MYTEL_CREDIT":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.operator === "mytel") {
            return { ...item, ...action.data };
          }
          return item;
        }),
      };
    case "START_LOADING":
      return { ...state, loading: true };
    case "END_LOADING":
      return { ...state, loading: false };
    default:
      return { ...state };
  }
};
export default operatorCredits;
