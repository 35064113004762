// ** Initial State
const initialState = {
  socketNotis: [],
  orders: [],
  token: JSON.parse(localStorage.getItem("accessToken")),
};

// console.log('sssssssssssss', selectedUser)

const socket = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_SOCKET_NOTIS_HISTORY":
      return { ...state, socketNotis: [...action.data] };
    case "ADD_NEW_NOTIS":
      return { ...state, socketNotis: [action.data, ...state?.socketNotis] };
    case "DELETE_SINGLE_SOCKET":
      return { ...state, socketNotis: [...action.data] };
    // case "DELETE_SINGLE_SOCKET_ORDER":
    //   return { ...state, orders: [...action.data] };
    // case "GET_ALL_ORDERS":
    //   return { ...state, orders: [...action.data] };
    // case "ADD_NEW_ORDER":
    //   return { ...state, orders: [action.data, ...state?.orders] };
    // case "REMOVE_ORDER":
    //   return {
    //     ...state,
    //     orders: [...state?.orders?.filter((each) => each?._id !== action.id)],
    //   };
    default:
      return { ...state };
  }
};
export default socket;
